<template>
  <div class="collection-notice ">
    <div class="title">
      <h2>集采公告</h2>
      <p @click="getMore">
        更多 >
      </p>
    </div>
    <div v-if="collectionList.length" class="card-wrapper">
      <div v-for="(item, index) in collectionList" :key="index" class="card" :class="[item.projectStatus != 2 ? 'has-closed' : 'register']" @click="gotoCollectionDetail(item)">
        <div>
          <h4 class="elp-2">
            {{ item.projectName }}
          </h4>
          <p class="company">
            {{ item.publishCmpName }}
          </p>
          <div class="collection-info">
            <span>{{ item.goodsName }}</span>
          </div>
          <p class="collection-item">
            <span class="collection-item-label">集采总量:</span> <span class="collection-item-value">{{ item.centralizedPurchaseTotalQuantity }}{{ $getDictNameByData($store.getters.getDictionaryItem('purchaseUnit'), item.meterageUnit) }}</span>
          </p>
          <p class="collection-item">
            <span class="collection-item-label">所在地区:</span> <span class="collection-item-value">{{ $getDictNameByData($store.getters.getDictionaryItem('PROVINCE'), item.placeProvince) }}</span>
          </p>
          <p class="collection-item">
            <span class="collection-item-label">报名截止:</span> <span class="collection-item-value time">{{ item.enterEndDate }}&nbsp;23:59</span>
          </p>
          <p class="collection-price">
            <span class="price">￥{{ item.centralizedPurchasePrice }}</span><span>/{{ $getDictNameByData($store.getters.getDictionaryItem('purchaseUnit'), item.meterageUnit) }}</span>
          </p>
        </div>
      </div>
    </div>
    <NoData v-else class="noData" :type="5" />
  </div>
</template>
<script>
import NoData from '@/components/NoData.vue'
import { getCollectionList } from '@/http/collection'
export default {
  components: {
    NoData
  },
  data() {
    return {
      collectionList: [],
      activeIndex: 0
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      getCollectionList({
        pageNum: 1,
        pageSize: 4,
        publishTimeOrderBy: 0,
        enterEndDateOrderBy: 0,
        notEqProjectStatus: 1
      }, res => {
        this.collectionList = res.data.records
      })
    },
    getMore() {
      this.$router.push('/collection/index')
    },
    gotoCollectionDetail({ projectId }) {
      this.$router.push({
        path: '/collection/notice',
        query: { projectId }
      })
    }
  }
}
</script>
<style lang="scss">

.collection-notice {

    margin: 16px auto;
    width: $centerPlateWidth;
    background-color: #fff;
    .title {
        display: flex;
        height: 74px;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px;
        p {
            font-size: 14px;
            color: #666;
        }
    }
    .card-wrapper {
        display: flex;
    }
    .active {
        box-shadow: 0 3px 30px 0 rgba(0,0,0,0.05), 0 8px 24px 0 rgba(0,0,0,0.04), 0 4px 10px 0 rgba(0,0,0,0.04);
    }
    .card {
        padding: 32px 24px 28px;
        display: flex;
        cursor: pointer;
        border-right: 1px solid #E7E7E7;
        border-top: 1px solid #E7E7E7;
        position: relative;
        width: 251px;
        h4 {
            font-size: 20px;
        }
        .company {
            line-height: 28px;
            font-size: 14px;
            color: #666;
        }
        .collection-info {
            margin: 20px 0;
           span {
                font-size: 14px;
                background: rgba(211,30,14,0.08);
                color: #D31E0E;
                padding: 5px;
           }
        }
        .collection-item {
            height: 30px;
            line-height: 30px;
            &-label {
                color: #666;
            }
            &-value {
                color: #333;
                margin-left: 10px;
            }
            .time {
                color: #000;
                font-weight: 500;
            }
        }
        .collection-price {
            margin-top: 25px;
            .price {
                font-size: 27px;
                font-weight: 500;
                color: #D31E0E;
            }
        }

    }
    .card:hover {
        box-shadow: 0 3px 30px 0 rgba(0,0,0,0.05), 0 8px 24px 0 rgba(0,0,0,0.04), 0 4px 10px 0 rgba(0,0,0,0.04);
        border: 1px solid #D31E0E;
    }
    .has-closed {
        background: url('../../../assets/img/hasClosed.png') no-repeat top right;
    }
    .register {
        background: url('../../../assets/img/register.png') no-repeat top right;
    }
    .noData {
      padding-bottom: 50px;
      .imageBox img {
        width: 200px !important;
        height: 200px !important;
        margin: 50px 0 !important;
      }
    }
}
</style>
