<template>
  <div class="homePageBox home-mian-box">
    <Slideshow />
    <!-- 广告图片 -->
    <div class="advertisingBox">
      <ul>
        <li v-for="item in advertisingList" :key="item.img">
          <router-link :to="{path: item.path}" target="_blank">
            <img :src="item.img" alt="">
            <div>
              <p class="title">
                {{ item.title }}
              </p>
              <p class="word">
                {{ item.word }}
              </p>
            </div>
          </router-link>
        </li>
      </ul>
      <!-- <img
        class="advertisingImg"
        src="@/assets/img/advertising.jpg"
        alt="图片"
      > -->
    </div>
    <!-- 现场直销 -->
    <ListedSpot />
    <!-- 集采公告 -->
    <CollectionNotice />
    <!-- 热门求购 -->
    <HotBuy />

    <!-- 平台交易指数跟新闻 -->
    <TradingIndexAndNews />
    <!-- 平台的小标语 -->
    <div class="platformSlogan">
      <div class="single">
        <img src="@/assets/img/slogan1.png" alt="icon"><span>行业专业的钢铁电商</span>
      </div>
      <div class="single">
        <img src="@/assets/img/slogan2.png" alt="icon"><span>优质可靠的现货资源</span>
      </div>
      <div class="single">
        <img src="@/assets/img/slogan3.png" alt="icon"><span>交易合同电子化服务</span>
      </div>
      <div class="single">
        <img src="@/assets/img/slogan4.png" alt="icon"><span>透明安全的在线交易</span>
      </div>
    </div>
  </div>
</template>

<script>
import TradingIndexAndNews from './component/TradingIndexAndNews.vue'
import Slideshow from './component/slideshow.vue'
import HotBuy from './component/hotBuy.vue'
import ListedSpot from './component/listedSpot'
import CollectionNotice from './component/collectionNotice.vue'
export default {
  components: { Slideshow, HotBuy, ListedSpot, TradingIndexAndNews, CollectionNotice },
  data() {
    return {
      advertisingList: [
        { img: require('../../assets/image/sjl1.png'),
          title: '生金链',
          word: '出资帮你锁定商业价值',
          path: '/fourGoldenTreasures/index?type=0'
        },
        { img: require('../../assets/image/sjl2.png'),
          title: '采金链',
          word: '一手资源丰富 直达上游钢厂',
          path: '/fourGoldenTreasures/index?type=1'
        },
        { img: require('../../assets/image/sjl3.png'),
          title: '赊金链',
          word: '助力供应商提前获得销售回款',
          path: '/fourGoldenTreasures/index?type=2'
        },
        { img: require('../../assets/image/sjl4.png'),
          title: '票金链',
          word: '一站式票据结算和票据融资',
          path: '/fourGoldenTreasures/index?type=3'
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.home-mian-box.homePageBox {
  // padding-top: 16px;
  background-color: rgba(249, 249, 249, 1);
  .platformSlogan{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
    .single{
      color: #333;
      font-size: 20px;
      img{
        height: 40px;
        width: 40px;
        margin-right: 8px;
      }
    }
    >div{
      flex:  0 0 300px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .advertisingBox {
    width: $centerPlateWidth;
    margin: auto;
    margin-bottom: 16px;
    background-color: #fff;
    ul {
      display: flex;
      justify-content: space-between;
      li {
        padding: 20px 24px;
        cursor: pointer;
        width: 25%;
        a {
          width: 100%;
          display: flex;
        }
        &:hover {
          background: rgba(249,249,249,1);
          .title {
            color: $themeColors;
          }
        }
        img {
          width: 48px;
          margin-right: 12px;
        }
        title {
          color: #000000;
          font-size: 18px;
        }
        .word {
          color: $fontColor5;
          font-size: 14px;
          margin-top: 8px;
        }
    }
    }
    .advertisingImg {
      height: 120px;
      width: 100%;
    }
  }
}
</style>
