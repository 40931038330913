<template>
  <div class="hotBuyPageBox on-site-direct-sales">
    <div class="list">
      <img class="bgImage" src="@/assets/img/recommended.png" alt="img">
      <div class="button" @click="skip">
        <img src="@/assets/img/button.png" alt="button">
        <span>查看全部</span>
      </div>
    </div>
    <div v-for="item in listData" :key="item.id" target="_blank" class="singleBox" @click="skip(item)">
      <div class="single">
        <img v-if="item.goodsMainImgUrl.indexOf('http')>=0" :src="item.goodsMainImgUrl" alt="产品图片" :onerror="defaultImage">
        <img v-else :src="$fileUrl + item.goodsMainImgUrl" alt="产品图片" :onerror="defaultImage">
        <p class="name elp-1">
          {{ item.goodsName }}
        </p>
        <p class="price">
          ￥{{ item.unitPriceIncludingTax }}
        </p>
        <div class="weight">
          <span class="city elp-1">{{ item.placeProvince }}{{ item.placeCity }}</span><span class="dun">{{ item.availableListingWeight }}吨</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { goodsHallPageSpotHallGoods } from '@/http/hangOut'
export default {
  data() {
    return {
      formInline: {
        pageSize: 5,
        pageNum: 1
      },
      listData: [],
      defaultImage: 'this.src="' + require('../../../assets/fourGoldenTreasuresImg/image/defaultImage.png') + '"'
    }
  },
  created() {
    this.getTradeGoodsHallPage()
  },
  methods: {
    skip({ id, shopId }) {
      sessionStorage.activeHomePath = '/spotHall/index?goodsType=0'
      if (id.constructor !== String) {
        this.$router.push({ path: '/spotHall/index?goodsType=0' })
        return
      }
      const routeUrl = this.$router.resolve({
        path: '/spotHall/spotHallDetail',
        query: { id, shopId }
      })
      window.open(routeUrl.href, '_blank')
    },
    getTradeGoodsHallPage() {
      goodsHallPageSpotHallGoods(this.formInline, (res) => {
        this.listData = res.data.records
      })
    },
    viewDetails({ id }) {
      const routeUrl = this.$router.resolve({
        path: '/spotHall/spotHallDetail',
        query: { id }
      })
      window.open(routeUrl.href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.on-site-direct-sales.hotBuyPageBox {
  width: $centerPlateWidth;
  margin: 0 auto;
  display: flex;
  background-color: #fff;
  height: 280px;
  .singleBox{
    width: 200px;
    max-height: 280px;
    overflow: hidden;
    box-sizing: border-box;
    .single{
      padding: 0 16px;
      margin-top: 16px;
      border-right: 1px solid #e7e7e7;
      cursor: pointer;
      .weight{
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #999;
        font-size: 12px;
        margin-top: 8px;
        .city{
          flex: 1;
        }
      }
      .price{
        color: $themeColors;
        font-size: 20px;
        transform: translateX(-4px);
      }
      .name{
        margin: 8px 0 0 0;
        color: $mainFont;
        font-size: 14px;
      }
      img{
        height: 168px;
        width: 168px;
      }
    }

  }
  .singleBox:last-of-type .single{
      border-right: 1px solid #fff;
    }
  .singleBox:hover{
    border: 1px solid $themeColors;
    box-shadow: 0 3px 30px 0 rgba(0,0,0,0.05), 0 8px 24px 0 rgba(0,0,0,0.04), 0 4px 10px 0 rgba(0,0,0,0.08);
  }
  .list{
    position: relative;
    width: 200px;
    height: 280px;
    .button{
      position: absolute;
      bottom: 64px;
      left: 36px;
      width: 130px;
      height: 38px;
      cursor: pointer;
      span{
        position: absolute;
        left: 50%;
        top: 6px;
        transform: translateX(-50%);
        color: #C93728;
        font-size: 16px;
      }
      img{
        height: 100%;
        width: 100%;
      }
    }
    .bgImage{
      width: 100%;
      height: 100%;
    }
  }
}
</style>
