import { postRequest } from './index'
import { SCFURL, BSERURL } from '@/config'

// 校验是否存在可用授信--供商城跳转供应链金融使用
export const creditValidateCredit = (successCallback, failureCallback) => {
  postRequest(`${SCFURL}/credit/validate/credit`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 开通指定系统并登录
export const cmpOpenSystem = (data, successCallback, failureCallback) => {
  postRequest(`${BSERURL}/cmp/open/system`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
